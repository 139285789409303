<template>
  <layout>
    <section class="breadcrumbs-bg">
        <div class="container">
          <h1 v-if="pageData">{{ pageData.page_name }}</h1>
        </div>
    </section>

    <section class="inner-page-section py-5">
      <div class="container custom-container">
        <div class="row align-items-center">
            <div class="col-lg-5" v-if="pageData.page_file">
              <div class="dunkin-left">
                <img :src="getImgUrl(pageData.page_file)" :alt="'Spring 2024'" class="img-boxshadow">
              </div>
            </div> 
            <div class="col-lg-7 py-3" v-if="pageData.page_data">
              <div class="dunkin-right ps-md-5" v-html="pageData.page_data"></div>
            </div>
        </div>
        <div class="dunkin-gallery-bg" v-if="pageData.gallery && pageData.gallery.length > 0 && pageData.gallery[0].is_primary == 1">
          <div class="row" v-if="pageData.gallery[0] || pageData.gallery[1] || pageData.gallery[2] || pageData.gallery[3] || pageData.gallery[4]">
            <div class="col-md-4 d-g-100 col-40">
              <img v-if="pageData.gallery[0]" :src="getImgUrl(pageData.gallery[0].img_path)" :alt="'Gallery 1'" class="img-boxshadow">
            </div>
            <div class="col-md-4 d-g-50 col-30">
              <img v-if="pageData.gallery[1]" :src="getImgUrl(pageData.gallery[1].img_path)" :alt="'Gallery 2'" class="img-boxshadow">
              <img v-if="pageData.gallery[2]" :src="getImgUrl(pageData.gallery[2].img_path)" :alt="'Gallery 3'" class="img-boxshadow">
            </div>
            <div class="col-md-4 d-g-50 col-30">
              <img v-if="pageData.gallery[3]" :src="getImgUrl(pageData.gallery[3].img_path)" :alt="'Gallery 4'" class="img-boxshadow">
              <img v-if="pageData.gallery[4]" :src="getImgUrl(pageData.gallery[4].img_path)" :alt="'Gallery 5'" class="img-boxshadow">
            </div>
          </div>

          <div class="row" v-if="pageData.gallery.length > 6">
            <div class="col-md-4" v-for="(item, index) in pageData.gallery.slice(5)" :key="index">
              <img v-if="item.img_path" :src="getImgUrl(item.img_path)" :alt="'Gallery ' + (index + 6)" class="img-boxshadow">
            </div>
          </div>
        </div>

        <div class="dunkin-gallery-bg" v-else-if="pageData.gallery">
          <div class="row" v-if="pageData.gallery.length > 0">
            <div class="col-md-4" v-for="(item, index) in pageData.gallery" :key="index">
              <img v-if="item.img_path" :src="getImgUrl(item.img_path)" :alt="'Gallery ' + (index + 6)" class="img-boxshadow">
            </div>
          </div>
        </div>
      </div>
    </section>

    <GetInTouchElement></GetInTouchElement>
  </layout>
</template>
 
<script>
import Layout from '../Layout/Layout.vue';
import GetInTouchElement from '../Elements/GetInTouchElement.vue';
import axios from 'axios';
import $ from 'jquery';
import { useHead } from '@vueuse/head';
import { useRoute } from 'vue-router';
import { ref, onMounted } from 'vue';
 
export default {
  name: 'ComingSoonComponent',
  components: {
    Layout,
    GetInTouchElement
  },
  data(){
    return {
      pageData : {},
      imageVersion: 1
    }
  },
  setup() {
    const route = useRoute();
    const pageTitle = ref(''); // Initialize as empty
    const generateTitleFromUrl = (str) => {
      let formatted_str =  str
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
      return formatted_str + ' | LXG';
    };
    const updateTitle = async () => {
      const pTitle = generateTitleFromUrl(route.params.slug);
      const pageTitle = ref(pTitle);
      useHead({
        title: pageTitle.value,
        meta: [
          {
            name: 'description',
            content: 'This is my page description',
          },
          {
            property: 'og:title',
            content: 'My Open Graph Title',
          },
        ],
      });
    };

    // Fetch title on component mount
    onMounted(() => {
      updateTitle();
    });

    return {
      pageTitle
    };
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
    storeConfigurationData() {
      return this.$store.state.storeConfigurationData;
    },
    storeInformationData() {
      return this.$store.state.storeInformationData;
    }
  },
  watch: {
    storeInformationData(newData) {
      this.updatePageTitle(newData);
    }
  },
  created() {
    //document.title = 'LXG - Coming Soon';
    //this.fetchPageData();
  },
  methods: {
    fetchPageData(slug) {
      //axios.post('/get-dynamic-page-data', { slug: slug })
      axios.get(`/get-dynamic-page-data/${slug}`)
        .then(response => {
          const responseData = response.data.data[0];
          if (responseData) {
            this.pageData = responseData;
            let pageHtml = $.parseHTML(responseData.page_data)[0]?.data || '';
            pageHtml = pageHtml.replace('../images/', process.env.VUE_APP_IMG_URL + '/images/');
            this.pageData.page_data = pageHtml;
            //document.title = 'LXG - ' + this.pageData.page_name;
            
          } else {
            this.setNotFoundPage();
          }
        })
        .catch(error => {
          console.error('Error fetching page data:', error);
          this.setNotFoundPage();
        });
    },
    setNotFoundPage() {
      this.$router.push({ name: 'NotFound' });
    },
    async fetchImageVersion(){
      if (!this.storeConfigurationData) {
        this.$store.dispatch('fetchStoreConfiguration');
      }
    },
    getImgUrl(imgDBPath){
      if(this.storeConfigurationData){
        this.imageVersion = this.storeConfigurationData.css_js_version;
      }
      if(imgDBPath == '' || imgDBPath == null){
          return process.env.VUE_APP_IMG_URL+'/images/store_images/coming-soon.jpg';
      }
      return process.env.VUE_APP_IMG_URL+'/'+imgDBPath+'?v='+this.imageVersion;
    },
    updatePageTitle(data) {
      if (data && data.store_name) {
        //document.title = data.store_name;
      }
    }
  },
  async mounted() {
    document.body.classList.add('inner-page-bg');
    this.fetchPageData(this.slug);
    await this.fetchImageVersion();
  },
  beforeUnmount() {
    document.body.classList.remove('inner-page-bg');
  }
};
</script>