<template>
  <layout>
    <section class="breadcrumbs-bg">
        <div class="container">
            <h1>Products</h1>
        </div>
    </section>

    <section class="inner-page-section py-5">
        <div class="container">
            <div class="product-text text-center pb-4">
                <p class="m-0">Is the product you want missing from our catalog? Call us and talk about our product sourcing services!</p>
                <a href="#" class="m-0" v-if="storeConfigurationData"><strong>{{ storeConfigurationData.contact_number }}</strong></a>
                <p class="m-0" v-if="contacts.name && contacts.designation"><b>{{ contacts.name }}</b> ({{ contacts.designation }})</p>
            </div>
            
            <div class="product-sidebar-icon" @click="toggleCategoryPopup">
                <i class="fa fa-th-large" aria-hidden="true"></i> Category List
            </div>
            
            <div class="row align-items-start">
              <div :class="['col-md-auto product-sidebar', { 'open': isCategoriesVisible }]" v-if="categories.length">
                <ul>
                    <li v-for="(category, index) in categories" :key="index" :class="{ 'active-product': activeIndex === index }">
                      <a href="#" @click.prevent="chooseCategory(category.catalog_page_no, index)">{{ category.category_name }}</a>
                  </li>
                </ul>
              </div>
              <div class="col-md product-pdf">
                <div v-if="isLoading && !catalogPath && categories.length" class="loader"></div>
                <PDFJSViewer :page="`${page}`" :catalogPath="`${catalogPath}`" v-if="catalogPath"/>
              </div>
            </div>
        </div>
    </section>

    <GetInTouchElement></GetInTouchElement>
  </layout>
</template>
 
<script>
import axios from 'axios';
import Layout from '../Layout/Layout.vue';
import GetInTouchElement from '../Elements/GetInTouchElement.vue';
import PDFJSViewer from '../Elements/PDFJSViewer'
import { mapState, mapActions } from 'vuex';
/* eslint-disable */
export default {
  name: 'ProductsComponent',
  props: {
    categoryslug: String
  },
  components: {
    Layout,
    GetInTouchElement,
    PDFJSViewer
  },
  computed: {
    storeConfigurationData() {
      return this.$store.state.storeConfigurationData;
    },
    storeInformationData() {
      return this.$store.state.storeInformationData;
    },
    ...mapState(['isCategoryPopupVisible'])
  },
  watch: {
    storeInformationData(newData) {
      this.updatePageTitle(newData);
    },
    isCategoryPopupVisible(newVal, oldVal){
      if (!newVal && this.isCategoriesVisible) {
        this.isCategoriesVisible = false;
      }
    }
  },
  data() {
    return {
      selectedCat: 1,
      categories: [],
      contacts: [],
      page: 1,
      catalogPath: '',
      isLoading: true,
      activeIndex: null,
      isCategoriesVisible: false
    }
  },
  created() {
    //document.title = 'LXG - Products';
    if (!this.storeConfigurationData) {
      this.$store.dispatch('fetchStoreConfiguration');
    }
    this.fetchContactList();
    this.fetchCategoryList();
    this.fetchCatalogFilePath();
  },
  methods: {
    ...mapActions(['setMainMenuVisible', 'setCategoryPopupVisible']),
    fetchCategoryList() {
      axios.get('/get-all-categories', { responseType: 'json' })
        .then(response => {
            this.categories = response.data.data;
            var allCategories = response.data.data;
            if(this.categoryslug) {
              allCategories.forEach((category, index) => {
                if(this.categoryslug.toLowerCase() === category.category_slug.toLowerCase()){
                  this.activeIndex = index;
                  this.page = category.catalog_page_no;
                }
              });
            }
          })
        .catch(error => {
          return error
        });
    },
    // eslint-disable-next-line
    /*handleCategoryChange(event){
      this.page = this.selectedCat;
    },*/
    chooseCategory(pageno, index){
      this.page = pageno;
      this.activeIndex = index;
      this.isCategoriesVisible = false;
      //this.setMainMenuVisible(false);
    },
    fetchContactList() {
      axios.get('/get-contact-details', { responseType: 'json' })
        .then(response => {
            response.data.data.forEach((row) => {
                if(row.at_product_view == 'yes'){
                  this.contacts = row;
                }
            });
        })
        .catch(error => {
          return error
        });
    },
    async fetchCatalogFilePath() {
      try {
        const response = await axios.get('/get-catalog-data', { responseType: 'json' });
        this.catalogPath = `/lib/pdfjs/web/viewer.html?file=/${response.data.data[0].catalog_pdf}`;
		
		/*const response = await axios.get('/get-catalog-pdf', {
          responseType: 'blob' // important to specify blob response type
        });
        // Create a URL for the PDF blob
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
		this.catalogPath = `/lib/pdfjs/web/viewer.html?file=${url}`;*/
		this.isLoading = false;
      } catch (error) {
        console.error('Error fetching catalog data:', error);
        this.catalogPath = '';
		this.isLoading = false;
      }
    },
    updatePageTitle(data) {
      if (data && data.store_name) {
        document.title = data.store_name + ' - Products';
      }
    },
    toggleCategoryPopup() {
      this.isCategoriesVisible = !this.isCategoriesVisible;
      this.setMainMenuVisible(false);
      this.setCategoryPopupVisible(true);
    }
  },
  mounted() {
    document.body.classList.add('inner-page-bg');
  },
  beforeUnmount() {
    document.body.classList.remove('inner-page-bg');
  }
};
</script>