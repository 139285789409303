<template>
  <div>
    <iframe height="100%" width="100%" v-if="filePath" :src="`${filePath}`"></iframe>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'PDFJSViewer',
  props: {
    page: String,
    catalogPath: String
  },
  data() {
    return {
      filePath: null
    };
  },
  watch: {
    page(newVal, oldVal) {
      this.fetchFilePath();
    },
    catalogPath(newVal, oldVal) {
      this.fetchFilePath();
    }
  },
  mounted() {
    this.fetchFilePath();
  },
  methods: {
    async fetchFilePath() {
      //this.filePath = this.catalogPath+'?v='+new Date().getTime()+'#page='+this.page;
	  this.filePath = this.catalogPath+'#page='+this.page;
    }
  }
}
</script>

<style scoped>
div {
  width: 100%;
  height: 100vh;
}
</style>